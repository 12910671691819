import VKConnectReal from '@vkontakte/vk-bridge';
import VKConnectMock from '@vkontakte/vkui-connect-mock';
import * as VKActions from '../store/vk/actions';
import { getAppId, getVkId } from './_functions';
import { setInsets } from '../store/vk/actions';

const VKConnect = process.env.NODE_ENV === 'production' ? VKConnectReal : VKConnectMock;
const API_VERSION = '5.124';
// const TOKEN_SCOPE = 'photos';
const TOKEN_SCOPE = '';

// Инит приложения
export const initApp = () => dispatch => {
    return new Promise(resolve => {
        const handler = async e => {
            const vkEvent = e.detail;
            const type = vkEvent.type;
            const data = vkEvent.data;

            let found = false;
            if (type === 'VKWebAppInitResult') {
                found = true;
                resolve();
            }

            if (type === 'VKWebAppUpdateConfig') {
                dispatch(setInsets(data.insets));
                document.body.setAttribute('scheme', data.scheme);
                if (data.appearance === 'light') {
                    setStatusBarDark();
                } else {
                    setStatusBarLight();
                }
            }

            if (found) {
                VKConnect.unsubscribe(handler);
            }
        };
        VKConnect.subscribe(handler);
        VKConnect.send('VKWebAppInit', { no_toolbar: true });
    });
};

export const setStatusBarDark = () => {
    VKConnect.send('VKWebAppSetViewSettings', { status_bar_style: 'dark' });
};

export const setStatusBarLight = () => {
    VKConnect.send('VKWebAppSetViewSettings', { status_bar_style: 'light' });
};

export const getAuthToken = () => async dispatch => {
    return new Promise((resolve, reject) => {
        let handler = async e => {
            const vkEvent = e.detail;
            const type = vkEvent.type;
            const data = vkEvent.data;

            let found = false;
            switch (type) {
                case 'VKWebAppAccessTokenReceived':
                    found = true;
                    resolve(dispatch(VKActions.setAuthTokenSuccess(data.access_token)));
                    break;

                case 'VKWebAppAccessTokenFailed':
                    found = true;
                    reject(dispatch(VKActions.setAuthTokenFail()));
                    break;

                default:
                    break;
            }
            if (found) VKConnect.unsubscribe(handler);
        };
        VKConnect.subscribe(handler);
        VKConnect.send('VKWebAppGetAuthToken', { app_id: getAppId(), scope: TOKEN_SCOPE });
    });
};

export const getUserInfo = () => async dispatch => {
    return new Promise((resolve, reject) => {
        let handler = async e => {
            const vkEvent = e.detail;
            const type = vkEvent.type;
            const data = vkEvent.data;

            let found = false;
            switch (type) {
                case 'VKWebAppGetUserInfoResult':
                    found = true;
                    resolve(dispatch(VKActions.setVkUser(data)));
                    break;

                case 'VKWebAppGetUserInfoFailed':
                    found = true;
                    reject(dispatch(VKActions.setVkUserFail()));
                    break;

                default:
                    break;
            }
            if (found) VKConnect.unsubscribe(handler);
        };
        VKConnect.subscribe(handler);
        VKConnect.send('VKWebAppGetUserInfo', {});
    });
};

// export const postWallShareResult = (message, image, qid) => async dispatch => {
//     return new Promise((resolve, reject) => {
//         let handler = async e => {
//             const vkEvent = e.detail;
//             const type = vkEvent.type;
//             const data = vkEvent.data;
//
//             let found = false;
//             switch (type) {
//                 case 'VKWebAppShowWallPostBoxResult':
//                     found = true;
//                     resolve(data);
//                     dispatch(addShareQuestions(qid));
//                     break;
//
//                 case 'VKWebAppShowWallPostBoxFailed':
//                     found = true;
//                     resolve(data);
//                     break;
//
//                 default:
//                     break;
//             }
//             if (found) VKConnect.unsubscribe(handler);
//         };
//
//         message += '\n\nУзнай ответы на все свои вопросы тут\n➡️ vk.com/omgapp ⬅️';
//         message += '\n\n#omgvk';
//
//         VKConnect.subscribe(handler);
//         VKConnect.send('VKWebAppShowWallPostBox', {
//             message: message,
//             attachments: [image, 'https://vk.com/app' + APP_ID].join(', '),
//         });
//     });
// };

// Показать рекламу
export const showRewardAd = () => {
    return new Promise(resolve => {
        const handler = async e => {
            const vkEvent = e.detail;
            const type = vkEvent.type;
            const data = vkEvent.data;

            let found = false;
            switch (type) {
                case 'VKWebAppShowNativeAdsResult':
                    found = true;
                    resolve(true);
                    break;

                case 'VKWebAppShowNativeAdsFailed':
                    found = true;
                    resolve(false);
                    break;

                default:
                    break;
            }
            if (found) {
                VKConnect.unsubscribe(handler);
            }
        };

        // Максимальное время ожидания
        setTimeout(() => {
            VKConnect.unsubscribe(handler);
            resolve(false);
        }, 5000);

        VKConnect.subscribe(handler);
        VKConnect.send('VKWebAppShowNativeAds', { ad_format: 'reward' });
    });
};

// Показать рекламу
export const showIntersitialAd = () => {
    return new Promise(resolve => {
        const handler = async e => {
            const vkEvent = e.detail;
            const type = vkEvent.type;
            const data = vkEvent.data;

            let found = false;
            switch (type) {
                case 'VKWebAppShowNativeAdsResult':
                    found = true;
                    resolve(true);
                    break;

                case 'VKWebAppShowNativeAdsFailed':
                    found = true;
                    resolve(false);
                    break;

                default:
                    break;
            }
            if (found) {
                VKConnect.unsubscribe(handler);
            }
        };

        // Максимальное время ожидания
        setTimeout(() => {
            VKConnect.unsubscribe(handler);
            resolve(false);
        }, 5000);

        VKConnect.subscribe(handler);
        VKConnect.send('VKWebAppShowNativeAds', { ad_format: 'interstitial' });
    });
};

// Получаем таргетировавнную рекламу
export const getBlockAd = () => {
    return new Promise(resolve => {
        const handler = async e => {
            const vkEvent = e.detail;
            const type = vkEvent.type;
            const data = vkEvent.data;

            let found = false;
            switch (type) {
                case 'VKWebAppGetAdsResult':
                    found = true;
                    resolve({ result: 'success', data: data });
                    break;

                case 'VKWebAppGetAdsFailed':
                    found = true;
                    resolve({ result: 'fail', data: e });
                    break;

                default:
                    break;
            }
            if (found) {
                VKConnect.unsubscribe(handler);
            }
        };

        VKConnect.subscribe(handler);
        VKConnect.send('VKWebAppGetAds', {});
    });
};

export const showRewardAdWeb = () => {
    return new Promise(resolve => {
        if (window.admanInit !== undefined) {
            const onAdsReady = adman => {
                adman.onStarted(() => {});
                adman.onCompleted(() => resolve(true));
                adman.onSkipped(() => resolve(false));
                adman.onClicked(() => {});
                adman.start('preroll');
            };

            const onNoAds = () => resolve(null);

            setTimeout(() => resolve(false), 6000);

            window.admanInit(
                {
                    user_id: getVkId(),
                    app_id: getAppId(),
                    type: 'rewarded', // 'preloader' или 'rewarded' (по умолчанию - 'preloader')
                    // params: {preview: 1}   // для проверки корректности работы рекламы
                },
                onAdsReady,
                onNoAds,
            );
        } else {
            resolve(false);
        }
    });
};

export const turnOnNotify = async () => {
    return new Promise(resolve => {
        const handler = async e => {
            const vkEvent = e.detail;
            const type = vkEvent.type;
            const data = vkEvent.data;

            let found = false;
            switch (type) {
                case 'VKWebAppAllowNotificationsResult':
                    found = true;
                    resolve({ result: 'success', data: data });
                    break;

                case 'VKWebAppAllowNotificationsFailed':
                    found = true;
                    resolve({ result: 'fail', data: e });
                    break;

                default:
                    break;
            }
            if (found) {
                VKConnect.unsubscribe(handler);
            }
        };

        VKConnect.subscribe(handler);
        VKConnect.send('VKWebAppAllowNotifications', {});
    });
};

// Запостить в историю
export const shareToHistory = blob => {
    return new Promise(resolve => {
        const handler = async e => {
            e = e.detail;
            let found = false;
            switch (e.type) {
                case 'VKWebAppShowStoryBoxResult':
                    found = true;
                    resolve(true);
                    break;

                case 'VKWebAppShowStoryBoxFailed':
                    found = true;
                    resolve(false);
                    break;

                default:
                    break;
            }
            if (found) {
                VKConnect.unsubscribe(handler);
            }
        };

        VKConnect.subscribe(handler);
        VKConnect.send('VKWebAppShowStoryBox', {
            background_type: 'image',
            locked: true,
            blob,
            attachment: {
                text: 'watch',
                type: 'url',
                url: `https://vk.com/app${getAppId()}#story`,
            },
        });
    });
};

export const apiRequest = (
    method,
    params = {},
    accessToken = '',
    successCallback = undefined,
    failCallback = undefined,
) => async dispatch => {
    const requestId = process.env.NODE_ENV === 'production' ? getNewRequestId() : '324nnefj';

    return new Promise((resolve, reject) => {
        let handler = async e => {
            e = e.detail;
            let found = false;

            if (e.data.request_id === requestId) {
                switch (e.type) {
                    case 'VKWebAppCallAPIMethodResult':
                        found = true;
                        if (successCallback !== undefined) successCallback(e.data.response);
                        resolve(e.data.response);
                        break;

                    case 'VKWebAppCallAPIMethodFailed':
                        found = true;
                        if (failCallback !== undefined) failCallback(e.data);
                        reject(e.data);
                        break;

                    default:
                        break;
                }
                if (found) VKConnect.unsubscribe(handler);
            }
        };

        VKConnect.subscribe(handler);

        params['access_token'] = accessToken;

        if (params['v'] === undefined) {
            params['v'] = API_VERSION;
        }

        VKConnect.send('VKWebAppCallAPIMethod', {
            method: method,
            params: params,
            request_id: requestId,
        });
    });
};

function getNewRequestId() {
    return Math.floor(Math.random() * Number.MAX_SAFE_INTEGER).toString();
}
