import { showIntersitialAd, showRewardAd, showRewardAdWeb } from './vk';
import { AD_CHANCE, AD_MOB_CHANCE, IS_AD_ON, IS_GAME_APP_AD_ON, IS_MINI_APP_AD_ON } from './_locals';

// Падеж
export const wordPad = (num, t, ta, tov) => {
    num = num % 100;
    if (num % 10 === 1 && (num < 10 || num > 20)) return t;
    else if (num % 10 >= 2 && num % 10 <= 4 && (num < 10 || num > 20)) return ta;
    else return tov;
};

// Время в часы, минуты, секунды
export const niceTime = seconds => {
    let minutes = Math.floor(seconds / 60);
    let hours = Math.floor(seconds / 3600);

    if (hours >= 1) return hours + ' час ' + (minutes - hours * 60) + ' мин';
    else if (minutes >= 1) return minutes + ' мин';
    else return seconds;
};

// Время в HH:MM:SS
export const formatTime = (sec, withHours = true, withMinutes = true, forceZeroes = false) => {
    let hours = Math.floor(sec / 3600);
    let minutes = Math.floor(sec / 60) - hours * 60;
    let seconds = sec - hours * 60 - minutes * 60;

    if (hours < 10 && forceZeroes) hours = '0' + hours;
    if (withHours) hours = hours + ':';
    else hours = '';

    if (minutes < 10 && (forceZeroes || withHours)) minutes = '0' + minutes;
    if (withMinutes) minutes = minutes + ':';
    else minutes = '';

    if (seconds < 10 && (forceZeroes || withMinutes)) seconds = '0' + seconds;

    return hours + minutes + seconds;
};

// Нули для больших чисел
export const numberWithSpaces = x => {
    let parts = x.toString().split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
    return parts.join('.');
};

// Преобразовать первую букву в заглавную
export const capitalize = str => {
    return str.charAt(0).toUpperCase() + str.slice(1);
};

// Добавить нули перед числом
export const numberWithZeros = (num, amount = 2) => {
    return '0'.repeat(amount - String(num).length) + num;
};

// Проверка на INT
export const isInt = value => {
    return (
        !isNaN(value) &&
        (function(x) {
            return (x | 0) === x;
        })(parseFloat(value))
    );
};

// Генерировать текст успешного прохождения
export const postWallSuccessText = (typeText, mistakesAmount) => {
    let mistakesText = 'Без ошибок';
    if (mistakesAmount > 0)
        mistakesText = 'С ' + mistakesAmount + wordPad(mistakesAmount, ' ошибкой', ' ошибками', ' ошибками');

    return (
        mistakesText +
        ' прошел ' +
        typeText +
        '.\n' +
        '\n' +
        'Учу правила дорожного движения и готовлюсь к экзамену на получение прав в официальном приложении ВКонтакте ПДД 2019.'
    );
};

// Проверка на существование
export const checkUndefined = (params, value, result) => {
    return params[value] !== undefined ? params[value] : result;
};

// Случайное INT в интервале, min, max включительно
export const randomIntFromInterval = (min, max) => {
    return Math.floor(Math.random() * (max - min + 1) + min);
};

// Случайное FLOAT в интервале, min, max включительно
export const randomFloatFromInterval = (min, max, num) => {
    return Math.floor((Math.random() * (max - min + 1) + min) * (10 ^ num)) / (10 ^ num);
};

// Случайные из массива с учетом весов
export const weightedRandom = arr => {
    let i,
        sum = 0,
        r = Math.random();
    for (i in arr) {
        sum += arr[i];
        if (r <= sum) return +i;
    }
};

export const getVkId = () => {
    if (isMiniApp()) {
        return new URL(window.location.href).searchParams.get('vk_user_id');
    } else {
        return new URL(window.location.href).searchParams.get('viewer_id');
    }
};

export const getAppId = () => {
    if (isMiniApp()) {
        return +new URL(window.location.href).searchParams.get('vk_app_id');
    } else {
        return +new URL(window.location.href).searchParams.get('api_id');
    }
};

export const getPlatform = () => {
    if (isMiniApp()) {
        return new URL(window.location.href).searchParams.get('vk_platform');
    } else {
        return new URL(window.location.href).searchParams.get('platform');
    }
};

export const getHash = () => {
    if (isMiniApp()) {
        return '';
    } else {
        return new URL(window.location.href).searchParams.get('hash');
    }
};

export const isMiniApp = () => {
    return new URL(window.location.href).searchParams.get('api_id') === null;
};

export const isWeb = () => {
    return getPlatform() === 'desktop_web' || getPlatform() === 'web';
};

export const isDirectGamesWeb = () => {
    return !isMiniApp() && isWeb();
};

export const startMediaAd = () => {
    if ((isMiniApp() && IS_MINI_APP_AD_ON) || (!isMiniApp() && IS_GAME_APP_AD_ON)) {
        if (getPlatform() === 'desktop_web' || getPlatform() === 'web') {
            if (randomIntFromInterval(0, 100) <= AD_CHANCE) {
                showRewardAdWeb();
            }
        } else {
            if (randomIntFromInterval(0, 100) <= AD_MOB_CHANCE) {
                showRewardAd();
            } else {
                showIntersitialAd();
            }
        }
    }
};
