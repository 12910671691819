import React, { Component } from 'react';
import * as VK from '@vkontakte/vkui';
import { connect } from 'react-redux';
import { IS_GAME_APP_AD_ON, IS_MINI_APP_AD_ON } from '../services/_locals';
import { restart, setAnswersLoaded, setPictureNum } from '../store/omg/actions';
import { selectAnswer } from '../store/answers/actions';
import { getBlockAd, shareToHistory } from '../services/vk';
import { addShareQuestions, getAnswers } from '../services/db';
import { isDirectGamesWeb, isMiniApp, weightedRandom } from '../services/_functions';
import Icon24Replay from '@vkontakte/icons/dist/24/replay';
import share from '../modules/share';
import utils from '../modules/utils';
import OmgLoader from '../components/OmgLoader';
import OmgPictureSelector from '../components/OmgPictureSelector';
import OmgParticlesSelector from '../components/OmgParticlesSelector';
import QuestionsList from '../components/QuestionsList';
import BackButton from '../components/BackButton';
import '../../scss/PanelOmg.scss';
import '../../scss/OmgAd.scss';
import '../../scss/PanelDirectGame.scss';
import BG_IMAGE from '../../img/bg/omg-bg.jpg';
import { konvaLoadImageWithParams, konvaRenderLayerToBase64, konvaSafeAddImage } from 'mini-juice/konva';
import Konva from 'konva';
import { bridgeShareStoryWithOpenApp } from 'mini-juice/vk';

class PanelOmg extends Component {
    constructor(props) {
        super(props);

        this.isAdClosed = React.createRef();
        this.state = {
            leftPos: 0,
            pictureNum: undefined,
            answersLoaded: false,
            promoInterval: null,
            promoBannerContent: null,
            needLoadQuestions: 1,
        };
    }

    restart = (weights = undefined) => {
        let n = 0;
        let selectedAnswersNum = -1;
        while (n < 100) {
            selectedAnswersNum = weightedRandom(weights === undefined ? [...this.props.answersWeights] : weights) + 1;
            if (selectedAnswersNum !== this.props.pictureNum) break;
            n++;
        }
        this.props.dispatch(selectAnswer(selectedAnswersNum - 1));
        this.props.dispatch(setPictureNum(selectedAnswersNum));
        this.props.dispatch(restart());
    };

    restartClick = () => {
        this.restart();
    };

    // postWall = async () => {
    // await this.props.dispatch(randAd());
    // this.props.dispatch(
    //     postWallShareResult(
    //         this.props.selectedQuestion.title,
    //         this.props.selectedAnswer.photo,
    //         this.props.selectedQuestion.id,
    //     ),
    // );
    // };

    async componentWillMount() {
        await this.props.dispatch(getAnswers(this.props.selectedQuestion.id));
        this.props.dispatch(setAnswersLoaded());
    }

    componentDidMount() {
        if ((isMiniApp() && IS_MINI_APP_AD_ON) || (!isMiniApp() && IS_GAME_APP_AD_ON)) {
            this.showPromoAd();

            this.setState({
                promoInterval: setInterval(() => {
                    if (this.isAdClosed.current === false) {
                        this.showPromoAd();
                    }
                }, 10000),
            });
        }

        if (isDirectGamesWeb()) {
            document.getElementById('panelOmg').addEventListener('scroll', this.trackScrolling);
        }
    }

    componentWillUnmount() {
        clearInterval(this.state.promoInterval);

        if (isDirectGamesWeb()) {
            document.getElementById('panelOmg').removeEventListener('scroll', this.trackScrolling);
        }
    }

    showPromoAd = async () => {
        const blockBanner = await getBlockAd();
        if (blockBanner.result === 'success') {
            this.setState({ promoBannerContent: blockBanner.data });
        }
    };

    closePromo = () => {
        this.setState({ promoBannerContent: null });
        this.isAdClosed.current = true;
    };

    componentWillReceiveProps(nextProps) {
        if (!this.props.answersLoaded && nextProps.answersLoaded) this.restart([...nextProps.answersWeights]);
    }

    renderStory = async (icons, prediction) => {
        const layer = new Konva.Layer();

        const STORY_WIDTH = 1080;
        const STORY_HEIGHT = 1920;
        const MIDDLE_X = STORY_WIDTH / 2;

        // const konvaText = (text, params = {}, fontSize = 58) =>
        //     new Konva.Text({
        //         text,
        //         fontSize: fontSize,
        //         lineHeight: (fontSize + 10) / fontSize,
        //         fontFamily: 'VK_Sans_Display, serif',
        //         fontStyle: '500',
        //         align: 'center',
        //         fill: '#ffffff',
        //         ...params,
        //     });

        // ---------- //
        // Грузим фон //
        // ---------- //
        const shareBgImage = await konvaLoadImageWithParams(BG_IMAGE, {
            width: STORY_WIDTH,
            height: STORY_HEIGHT,
        });
        konvaSafeAddImage(layer, shareBgImage);
        //
        // // ---------------------------- //
        // // Гененируем стрелку и надпись //
        // // ---------------------------- //
        // const bottomGroup = new Konva.Group();
        //
        // // Надпись
        // const bottomLabel = konvaText('Узнай какое у тебя в\u00a0мини-приложении', {
        // 	width: 570,
        // });
        //
        // // Стрелка
        // const shareArrowImage = await konvaLoadImageWithParams(ARROW_IMAGE, {
        // 	width: 100,
        // 	height: 100,
        // 	x: 570 / 2 - 100 / 2,
        // 	y: bottomLabel.getClientRect().height + 32,
        // });
        //
        // bottomGroup.add(bottomLabel);
        // konvaSafeAddImage(bottomGroup, shareArrowImage);
        //
        // // ------------------ //
        // // Гененируем билетик //
        // // ------------------ //
        // const ticketGroup = new Konva.Group();
        //
        // // Верхняя часть билета
        // const ticketTopPart = await konvaLoadImageWithParams(TICKET_TOP_IMAGE, {
        // 	width: TICKET_WIDTH,
        // 	height: 138,
        // });
        //
        // // Надпись мое предсказание с датой
        // const month = DATE.MONTHS_ROD[monthNow()];
        // const day = dayNow();
        //
        // const topText = konvaText(`Моё предсказание на\u00a0${day}\u00a0${month}:`, {
        // 	width: 570,
        // 	x: TICKET_WIDTH / 2 - 570 / 2,
        // 	y: konvaHeight(ticketTopPart),
        // 	fill: '#362C66',
        // 	fontStyle: '400',
        // });
        //
        // // Слоты
        // const slotsGroups = [];
        // for (let i = 0; i < 3; i++) {
        // 	const slotGroup = new Konva.Group();
        //
        // 	const slotBg = new Konva.Rect({
        // 		width: 224,
        // 		height: 268,
        // 		fill: '#F2F3F5',
        // 		cornerRadius: 40,
        // 	});
        //
        // 	const slotIcon = await konvaLoadImageWithParams(getEmojiImage(icons[i]), {
        // 		width: 160,
        // 		height: 160,
        // 		x: konvaWidth(slotBg) / 2 - 160 / 2,
        // 		y: konvaHeight(slotBg) / 2 - 160 / 2,
        // 	});
        //
        // 	slotGroup.add(slotBg);
        // 	konvaSafeAddImage(slotGroup, slotIcon);
        //
        // 	slotsGroups.push(slotGroup);
        // }
        //
        // const slotsGroup = konvaFillHorizontalFullWidth(slotsGroups, 744);
        // slotsGroup.setAttrs({
        // 	y: konvaHeight(ticketTopPart) + konvaHeight(topText) + 44,
        // 	x: TICKET_WIDTH / 2 - 744 / 2,
        // });
        //
        // // Текст предсказания
        // const fontSize = prediction.length > 110 ? 48 : 58;
        // const predictionText = konvaText(prediction, {
        // 	width: 720,
        // 	x: TICKET_WIDTH / 2 - 720 / 2,
        // 	y: konvaHeight(ticketTopPart) + konvaHeight(topText) + konvaHeight(slotsGroup) + 44 + 44,
        // 	fill: '#000000',
        // 	fontStyle: '400',
        // }, fontSize);
        //
        // // Нижняя часть билета
        // const ticketBottomPart = await konvaLoadImageWithParams(TICKET_BOTTOM_IMAGE, {
        // 	width: TICKET_WIDTH,
        // 	height: 162,
        // 	y: konvaHeight(ticketTopPart) + konvaHeight(topText) + konvaHeight(slotsGroup) + konvaHeight(predictionText) + 44 + 44,
        // });
        //
        // // Фон билета
        // const ticketBg = new Konva.Rect({
        // 	width: TICKET_WIDTH,
        // 	height: konvaHeight(topText) + konvaHeight(slotsGroup) + konvaHeight(predictionText) + 44 + 44,
        // 	y: konvaHeight(ticketTopPart),
        // 	fill: '#ffffff',
        // });
        //
        // konvaSafeAddImage(ticketGroup, ticketTopPart);
        // ticketGroup.add(ticketBg);
        // ticketGroup.add(topText);
        // ticketGroup.add(slotsGroup);
        // ticketGroup.add(predictionText);
        // konvaSafeAddImage(ticketGroup, ticketBottomPart);

        // ---------- //
        // Общий слой //
        // ---------- //
        // bottomGroup.setAttrs({
        // 	x: MIDDLE_X - konvaWidth(bottomGroup) / 2,
        // 	y: STORY_HEIGHT - konvaHeight(bottomGroup) - 275,
        // });
        // layer.add(bottomGroup);
        //
        // ticketGroup.setAttrs({
        // 	x: MIDDLE_X - konvaWidth(ticketGroup) / 2,
        // 	y: konvaY(bottomGroup) - konvaHeight(ticketGroup) - 110,
        // });
        // layer.add(ticketGroup);

        return konvaRenderLayerToBase64(layer, { previewScale: 0.2, previewInConsole: 'on' });
    };

    shareWithFriends = async () => {
        const storyBase64 = this.renderStory();
        await bridgeShareStoryWithOpenApp(storyBase64);
    };
    //     const omgBg = require(`../../img/bg/omg-bg.jpg`);
    //     const bgUrl = this.props.selectedAnswer.link;
    //     const photo = await utils.loadFile(bgUrl);
    //
    //     const question = this.props.selectedQuestion.title;
    //
    //     let img = new Image();
    //     img.src = bgUrl;
    //
    //     img.onload = async () => {
    //         const newW = 1000;
    //         const k = newW / img.width;
    //         const newH = img.height * k;
    //         const midY = 1920 / 2 - newH / 2;
    //         const hForText = (1920 / 2 - newH / 2) / 2;
    //
    //         const story = new share.CanvasBuilder(1080, 1920);
    //         await story.addImage(omgBg, 0, 0, 1080, 1920);
    //         await story.addImage(photo, 40, midY, newW, newH);
    //
    //         const questionParts = question.split(' ');
    //
    //         const questionPartsJoined = [];
    //         let tmpStr = '';
    //         questionParts.forEach((part, i) => {
    //             if (tmpStr === '') {
    //                 tmpStr = part;
    //             } else {
    //                 tmpStr += ' ' + part;
    //             }
    //
    //             if (tmpStr.length > 18 || i === questionParts.length - 1) {
    //                 questionPartsJoined.push(tmpStr);
    //                 tmpStr = '';
    //             }
    //         });
    //         for (let i = 0; i < question.length; i++) {}
    //
    //         questionPartsJoined.forEach((part, i) => {
    //             story.addText(
    //                 part,
    //                 1080 / 2 + 5,
    //                 100 + i * 100 + hForText / questionPartsJoined.length,
    //                 '80px Roboto',
    //                 '#ffffff',
    //                 'center',
    //             );
    //             story.addText(
    //                 part,
    //                 1080 / 2,
    //                 100 + i * 100 + hForText / questionPartsJoined.length,
    //                 '80px Roboto',
    //                 '#000000',
    //                 'center',
    //             );
    //         });
    //
    //         story.addText('Узнай про себя!', 1080 / 2 + 5, 1780, '80px Roboto', '#ffffff', 'center');
    //         story.addText('Узнай про себя!', 1080 / 2, 1780, '80px Roboto', '#000000', 'center');
    //         story.addText('⬇️ ⬇️ ⬇️', 1080 / 2, 1890, '80px Roboto', '#000000', 'center');
    //
    //         story.render(async blob => {
    //             const scale = 4;
    //             const render = new share.CanvasBuilder(1080 / scale, 1920 / scale);
    //             await render.addImage(blob, 0, 0, 1080 / scale, 1920 / scale);
    //             // render.preview();
    //
    //             const isSuccessShare = await shareToHistory(blob);
    //
    //             if (isSuccessShare) {
    //                 if (isSuccessShare) {
    //                     this.props.dispatch(addShareQuestions(this.props.selectedQuestion.id));
    //                 }
    //             }
    //         });
    //     };
    // };

    // shareWithFriends = async () => {
    //     const omgBg = require(`../../img/bg/omg-bg.jpg`);
    //     const bgUrl = this.props.selectedAnswer.link;
    //     const photo = await utils.loadFile(bgUrl);
    //
    //     const question = this.props.selectedQuestion.title;
    //
    //     let img = new Image();
    //     img.src = bgUrl;
    //
    //     img.onload = async () => {
    //         const newW = 1000;
    //         const k = newW / img.width;
    //         const newH = img.height * k;
    //         const midY = 1920 / 2 - newH / 2;
    //         const hForText = (1920 / 2 - newH / 2) / 2;
    //
    //         const story = new share.CanvasBuilder(1080, 1920);
    //         await story.addImage(omgBg, 0, 0, 1080, 1920);
    //         await story.addImage(photo, 40, midY, newW, newH);
    //
    //         const questionParts = question.split(' ');
    //
    //         const questionPartsJoined = [];
    //         let tmpStr = '';
    //         questionParts.forEach((part, i) => {
    //             if (tmpStr === '') {
    //                 tmpStr = part;
    //             } else {
    //                 tmpStr += ' ' + part;
    //             }
    //
    //             if (tmpStr.length > 18 || i === questionParts.length - 1) {
    //                 questionPartsJoined.push(tmpStr);
    //                 tmpStr = '';
    //             }
    //         });
    //         for (let i = 0; i < question.length; i++) {}
    //
    //         questionPartsJoined.forEach((part, i) => {
    //             story.addText(
    //                 part,
    //                 1080 / 2 + 5,
    //                 100 + i * 100 + hForText / questionPartsJoined.length,
    //                 '80px Roboto',
    //                 '#ffffff',
    //                 'center',
    //             );
    //             story.addText(
    //                 part,
    //                 1080 / 2,
    //                 100 + i * 100 + hForText / questionPartsJoined.length,
    //                 '80px Roboto',
    //                 '#000000',
    //                 'center',
    //             );
    //         });
    //
    //         story.addText('Узнай про себя!', 1080 / 2 + 5, 1780, '80px Roboto', '#ffffff', 'center');
    //         story.addText('Узнай про себя!', 1080 / 2, 1780, '80px Roboto', '#000000', 'center');
    //         story.addText('⬇️ ⬇️ ⬇️', 1080 / 2, 1890, '80px Roboto', '#000000', 'center');
    //
    //         story.render(async blob => {
    //             const scale = 4;
    //             const render = new share.CanvasBuilder(1080 / scale, 1920 / scale);
    //             await render.addImage(blob, 0, 0, 1080 / scale, 1920 / scale);
    //             // render.preview();
    //
    //             const isSuccessShare = await shareToHistory(blob);
    //
    //             if (isSuccessShare) {
    //                 if (isSuccessShare) {
    //                     this.props.dispatch(addShareQuestions(this.props.selectedQuestion.id));
    //                 }
    //             }
    //         });
    //     };
    // };

    trackScrolling = () => {
        const wrappedElement = document.getElementById('panelOmg');
        if (this.isBottom(wrappedElement) && !this.props.isQuestionsLoading && !this.props.endReached) {
            this.setState(prevState => ({
                ...prevState,
                needLoadQuestions: prevState.needLoadQuestions + 1,
            }));
        }
    };

    isBottom(el) {
        const scroll = el.scrollTop;
        const panelHeight = el.clientHeight;
        const height = document.querySelector('.Panel__in').getBoundingClientRect().height;

        return scroll + panelHeight >= height - 50;
    }

    render() {
        const isLoaded = this.props.answersLoaded && this.props.pictureLoaded && this.props.loaderTimeElapsed;

        return (
            <VK.Panel id={this.props.id} className={isDirectGamesWeb() ? 'direct-game-panel' : ''}>
                <VK.PanelHeader left={<BackButton />}>OMG</VK.PanelHeader>
                <VK.Div className="panel-omg">
                    <div className="tags">{this.props.selectedQuestion.tags}</div>
                    <div className="title">{this.props.selectedQuestion.title}</div>
                    <div className="desc">{this.props.selectedQuestion.desc}</div>
                    <OmgPictureSelector
                        ptype={this.props.selectedQuestion.ptype}
                        pictureHeight={this.props.pictureHeight}
                        imagePictureKey={this.props.imagePictureKey}
                        loaded={isLoaded}
                    />
                    <div className="btn-1">
                        <VK.Button disabled={!isLoaded} size="xl" onClick={this.shareWithFriends}>
                            Поделиться с друзьями
                        </VK.Button>
                    </div>
                    <div className="btn-2">
                        <VK.Button
                            disabled={!isLoaded}
                            size="xl"
                            mode="secondary"
                            before={<Icon24Replay />}
                            onClick={this.restartClick}
                        >
                            Еще раз
                        </VK.Button>
                    </div>
                    {!isLoaded && (
                        <OmgLoader
                            preloader={this.props.preloader}
                            type={this.props.selectedQuestion.ptype}
                            setLoadedFunc={this.setLoadedFunc}
                        />
                    )}
                    {isLoaded && <OmgParticlesSelector particles={this.props.particles} />}
                </VK.Div>
                <VK.Div className="also">Вам также может понравиться ⬇️</VK.Div>
                <QuestionsList needLoadQuestions={this.state.needLoadQuestions} />
                {this.state.promoBannerContent !== null && (
                    <VK.PromoBanner onClose={this.closePromo} bannerData={this.state.promoBannerContent} />
                )}
            </VK.Panel>
        );
    }
}

const mapStateToProps = state => {
    return {
        accessToken: state.vk.accessToken,
        selectedQuestion: state.questions.selectedQuestion,
        selectedAnswer: state.answers.selectedAnswer,
        answersWeights: state.answers.answersWeights,
        answersLoaded: state.omg.answersLoaded,
        pictureLoaded: state.omg.pictureLoaded,
        preloader: state.omg.preloader,
        particles: state.omg.particles,
        loaderTimeElapsed: state.omg.loaderTimeElapsed,
        imagePictureKey: state.omg.imagePictureKey,
        pictureHeight: state.omg.pictureHeight,
        pictureNum: state.omg.pictureNum,
        selectedAd: state.ads.selectedAd,
        endReached: state.questions.endReached,
        isQuestionsLoading: state.questions.isQuestionsLoading,
    };
};

export default connect(mapStateToProps)(PanelOmg);
